import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';

import '@splidejs/splide/css';

import Splide from '@splidejs/splide';

var sliders = document.getElementsByClassName( 'splide' );

for ( var i = 0; i < sliders.length; i++ ) {
  new Splide( sliders[ i ], {
    arrowPath: 'M28.5357 19.2929C28.9262 19.6834 28.9262 20.3166 28.5357 20.7071L22.1717 27.0711C21.7812 27.4616 21.148 27.4616 20.7575 27.0711C20.367 26.6805 20.367 26.0474 20.7575 25.6569L26.4144 20L20.7575 14.3431C20.367 13.9526 20.367 13.3195 20.7575 12.9289C21.148 12.5384 21.7812 12.5384 22.1717 12.9289L28.5357 19.2929ZM10 19L27.8286 19V21L10 21V19Z',
    gap:'1.5rem',
    pagination: false,
    breakpoints: {
      640: {
        perPage: 1,
      },
      768: {
        perPage: 2,
      }
    }
  } ).mount();
}

// Bubbles Component
document.addEventListener('alpine:init', () => {
  Alpine.data('bubbles', () => ({
    activeIndex: null,
    centerPosition: { x: 0, y: 0 },
    screenSize: 'large',
    bubblePositions: {
      xsmall: [
        { left: '40%', top: '55%' },  // People Sustainability
        { left: '70%', top: '72%' },  // Strategieentwicklung
        { left: '30%', top: '17%' },  // Sparring
        { left: '30%', top: '85%' },  // Strategische Kommunikation
        { left: '70%', top: '35%' }   // Leadership & Führung
      ],
      small: [
        { left: '40%', top: '55%' },  // People Sustainability
        { left: '70%', top: '72%' },  // Strategieentwicklung
        { left: '30%', top: '17%' },  // Sparring
        { left: '30%', top: '85%' },  // Strategische Kommunikation
        { left: '70%', top: '35%' }   // Leadership & Führung
      ],
      medium: [
        { left: '25%', top: '25%' },  // People Sustainability
        { left: '75%', top: '20%' },  // Strategieentwicklung
        { left: '55%', top: '50%' },  // Sparring
        { left: '25%', top: '75%' },  // Strategische Kommunikation
        { left: '80%', top: '80%' }   // Leadership & Führung
      ],
      large: [
        { left: '35%', top: '21%' },  // People Sustainability
        { left: '84%', top: '20%' },  // Strategieentwicklung
        { left: '63%', top: '45%' },  // Sparring
        { left: '36%', top: '65%' },  // Strategische Kommunikation
        { left: '83%', top: '73%' }   // Leadership & Führung
      ]
    },
    
    init() {
      // Calculate container center
      this.calculateCenter();
      
      // Set initial screen size
      this.updateScreenSize();
      
      // Handle window resize
      window.addEventListener('resize', () => {
        this.calculateCenter();
        this.updateScreenSize();
      });
    },
    
    updateScreenSize() {
      const width = window.innerWidth;
      
      if (width < 480) {
        this.screenSize = 'xsmall';
      } else if (width < 768) {
        this.screenSize = 'small';
      } else if (width < 1024) {
        this.screenSize = 'medium';
      } else {
        this.screenSize = 'large';
      }
    },
    
    calculateCenter() {
      const container = document.getElementById('bubbles-container');
      if (container) {
        const rect = container.getBoundingClientRect();
        this.centerPosition = {
          x: rect.width / 2,
          y: rect.height / 2
        };
      }
    },
    
    getBubbleStyle(index) {
      if (this.activeIndex === index) {
        // Center the active bubble
        return {
          left: `50%`,
          top: `50%`
        };
      } else if (this.activeIndex !== null) {
        // Move inactive bubbles to corners when one is active
        // Different corner positions for different screen sizes
        const cornerPositions = {
          xsmall: [
            { left: '20%', top: '15%' },
            { left: '80%', top: '15%' },
            { left: '20%', top: '85%' },
            { left: '80%', top: '85%' },
            { left: '50%', top: '15%' }
          ],
          small: [
            { left: '20%', top: '15%' },
            { left: '80%', top: '15%' },
            { left: '20%', top: '85%' },
            { left: '80%', top: '85%' },
            { left: '50%', top: '15%' }
          ],
          medium: [
            { left: '15%', top: '10%' },
            { left: '85%', top: '10%' },
            { left: '15%', top: '85%' },
            { left: '85%', top: '85%' },
            { left: '50%', top: '10%' }
          ],
          large: [
            { left: '10%', top: '21%' },
            { left: '80%', top: '20%' },
            { left: '10%', top: '76%' },
            { left: '80%', top: '80%' },
            { left: '45%', top: '10%' }
          ]
        };
        
        // Get positions for current screen size
        const positions = cornerPositions[this.screenSize];
        
        // Skip the active bubble's position
        const posIndex = index > this.activeIndex ? index - 1 : index;
        return positions[posIndex];
      } else {
        // Use the responsive positions based on screen size
        return this.bubblePositions[this.screenSize][index];
      }
    },
    
    setActiveBubble(index) {
      this.activeIndex = index;
    },
    
    toggleBubble(index) {
      if (this.activeIndex === index) {
        this.activeIndex = null;
      } else {
        this.activeIndex = index;
      }
    }
  }));
});

// Components
Alpine.plugin(collapse);

// Global
window.Alpine = Alpine;

// Inits
Alpine.start();